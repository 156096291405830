.time {
    font-size: 2em;
    font-weight: 500;
    color: #fff;
}

.dimension {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 10px;
}

.buttonClaim {
    background-color: #00b939;
    width: 80%;
    height: 2.8em;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    border: 1px solid #00b939;
}
.buttonClaim:hover{
    border: 1px solid #00b939;
    
}

.moneyBack {
    text-transform: capitalize;
    padding-bottom: 3px;
    font-weight: 600;
    font-size: 14px;
}

.subscriptionIncludes h2 {
    /* border-bottom: 2px solid #fff; */
    border-bottom: 2px solid;
    border-image: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%) 1;
    box-sizing: content-box;
    width: 60%;
    color: #fff;
    font-size: 1.4em;
    font-weight: 600;
    position: relative;
    margin-left: 17px;
    margin-bottom: 18px;
}

.subscriptionIncludes h2:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: -2px;
    border-bottom: 2px solid #40e65b;
    width: 74px;
}
.lifeTimeBanner{
    background-image: url("/assets/images/life-time/life-time-banner.png");
    background-position: center;
    background-repeat: no-repeat;
    height: 800px;
}

.lifeTimeBanner h1 {
    margin-top: 100px;
}

.offerBadge {
    background: #ff0000ab;
    display: inline-block;
    padding: 5px 14px;
    color: #fff;
    font-weight: 600;
    box-shadow: 1px 1px 10px #02020296;
}

.stayProtect{
    background-image: url("/assets/images/life-time/stay-protect-banner.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;

}
.stayProtectInner{
    padding: 80px 0px 80px 0px;
    position: relative;
}
.stayProtectheading span{
    font-size: 30px;
    color: #000;
    font-weight: 300;
    text-transform: uppercase;
}
.stayProtectheading p{
    font-size: 42px;
    color: #000;
    line-height: 1;
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
}
.stayProtectheading h3{
    font-size: 66px;
    color: #005db2;
    line-height: 1;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 30px;
}
.stayContent p{
    font-size: 16px;
    color: #000;
}
.stayList{
    list-style: none;
    width: fit-content;
    line-height: 2;
}
.stayList li{
    color: #000;
    font-size: 14px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.stayList li::before{
    content: "";
    width: 6px;
    height: 6px;
    background: #00b939;
    display: inline-flex;
    margin-right: 10px;
    transform: rotate(45deg);
}
.stayBtn{
    width: 46%;
}
.boxFriday{
    background-color: #fff;
    border: 1px solid rgb(0 0 0 / 10%);
    border-radius: 30px;
    padding: 50px 20px 20px 20px;
    position: relative;
    z-index: 0;
    margin: 0 auto 50px auto;
    min-width: 320px;
    box-shadow: 0 0 10px rgb(0 0 0 / 5%);
    text-align: center;
}
.boxCount{
    position: absolute;
    top: 10px;
    left: 10px;
    min-width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    background-color: #000000;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    border-radius: 50px;
    box-sizing: border-box;
}
.boxContent h3{
    color: #000;
    font-size: 20px;
    font-weight: 700;
}
.headFridayContent h3{
    color: #000;
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
}
.boxImg{
    margin-bottom: 15px;
}
.laborDaySubscriptionIncludes {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.laborDaySubscriptionIncludes li {
    width: 50%;
    display: flex;
    align-items: center;
}

.laborDaySubscriptionIncludes li .title {
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
    flex: 1;
}

.pricingBoxWrapper {
    padding: 3px;
    border-radius: 15px;
    text-align: center;
    width: 415px;
}

.pricingBox {
    background-color: #fff;
    height: 450px;
    border-radius: 15px;
    width: 407px;
    border: dashed 4px #0e6d1d;
}

.pricingBox h2 {
    text-align: center;
    padding-top: 15px;
    font-weight: bold;
    color: #000;
    font-size: 30px;
    margin-bottom: 1rem;
}

.pricingImage {
    text-align: center;
    background-image: url("/assets/images/life-time/box-banner.jpg");
    background-position: center;
    background-repeat: no-repeat;
    height: 260px;
}


.pricingImage p {
    color: #fff;
    margin: 0;
    font-size: 3.3em;
    font-weight: 700;
    line-height: 0px;
    padding-top: 3.5rem;
}

.pricingImage p:last-child {
    font-size: 2.5em;
    font-weight: 500;
}

button.buyVpnText {
    width: 100%;
    background-color: #00b939;
}

button.buyVpnText:first-child:active {
    background-color: #079132;
}

button.buyVpnText .border {
    position: relative;
    align-self: center;
    height: 28px;
    font-size: 1.1em;
    font-weight: 600;
}

button.buyVpnText .border i {
    margin-left: 10px;
}

button.buyVpnText .border::after {
    content: " ";
    position: absolute;
    border-right: 1px solid #fff;
    height: 100%;
    right: -19px;
}

button.buyVpnText div div:last-child {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 22px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .time {
        font-size: 1.4em;
    }

    .dimension {
        font-size: 0.8em;
    }

    .mainStarted {
        margin-top: 40px;
    }

    .lifeTimeBanner {
        height: auto;
        background-color: #00819b;
        padding-bottom: 80px;
        background-position: top center;
        height: auto;
    }

    .lifeTimeBanner h1 {
        
    }

    .offerBadge {
        margin-top: 20px;
    }

    .lifeTimeBanner > div {
        padding-bottom: 30px;
    }

    .pricingImage {
        height: 260px;
    }

    .laborDaySubscriptionIncludes {
        display: block;
    }

    .subscriptionIncludes {
        display: block;
    }

    .subscriptionIncludes h2 {
        width: 100%;
        padding-bottom: 8px;
    }

    .laborDaySubscriptionIncludes li {
        width: 100%;
        margin-left: 12px;
    }

    .laborDaySubscriptionIncludes li img {
        width: 15%;
        margin-right: 5px;
    }

    .laborDaySubscriptionIncludes li .title {
        font-size: 14px;
    }

    .pricingBoxWrapper {
        width: 100%;
        height: 399px;
    }

    .pricingBox {
        height: 440px;
        width: 100%;
    }

    .pricingBox h2 {
        text-align: center;
        padding-top: 15px;
        font-weight: bold;
        color: #000;
        font-size: 22px;
    }

    .stayBtn {
        width: 100%;
        margin-bottom: 40px;
    }

    /* button.buyVpnText {
        width: 325px;
    } */


    button.buyVpnText .border::after {
        right: -17px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .laborDaySubscriptionIncludes li {
        width: 100%;
    }

    .laborDaySubscriptionIncludes li img {
        width: 55px;
    }

    .pricingBoxWrapper,
    .pricingBox {
        width: 100%;
    }

    .subscriptionIncludes h2 {
        width: 100%;
        padding-bottom: 10px;
    }

    .pricingBox h2 {
        font-size: 1em;
    }

    button.buyVpnText {
        width: 100%;
        margin-bottom: 6px;
    }
    button.buyVpnText .border {
        font-size: 12px;
    }

    button.buyVpnText .border::after {
        display: none;
        right: -17px;
    }

    button.buyVpnText div {
        line-height: 28px;
    }

    button.buyVpnText div div:last-child {
        display: none !important;
    }
}